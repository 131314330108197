@import "../assets/bootstrap-3.3.1/dist/css/bootstrap.css";
@import "../modules/accounting/styles.css";
@import "../modules/activity/styles.css";
@import "../modules/image/styles.css";
@import "../modules/property/styles.css";
@import "../modules/property-group/styles.css";
@import "../modules/reporting/styles.css";
@import "../modules/search/styles.css";
@import "../modules/shell/styles.css";
@import "common/context-menu.css";
@import "common/crud-table.css";
@import "common/file-upload.css";
@import "common/inline-input.css";
@import "common/image-collection.css";
@import "common/login.css";
@import "common/modal.css";
@import "glyphicons/glyphicons.css";
@import "glyphicons/glyphicons-bootstrap.css";
@import "glyphicons/glyphicons-halflings.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.11.0/css/lightbox.min.css');

.content-container {
	padding: 110px 20px 20px 20px;
}

.breadcrumb {
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.input-group-addon {
	min-width: 50px;
	text-align: center;
}

.display-inline {
	display: inline-block;
}

.text-red {
	color: red;
}

.spacer {
	width: 100%;
	font-size: 0;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
}

.spacer5 {
	height: 5px;
}

.ml-5 {
	margin-left: 5px;
}

.mr-5 {
	margin-right: 5px;
}

.rs-picker-toggle {
	border-radius: 2px !important;
	border-color: #ccc !important;
	padding-top: 6px !important;
	padding-bottom: 6px !important;
}

.nav-tabs > li.active > a {
	background-color: #fff !important;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text { color: transparent; }


/* ------------------------------------------------------------- *
 * Media Queries
 * ------------------------------------------------------------- */

@media (max-width: 1050px) {
	.context-menu label {
		display: none;
	}
}

@media print , only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 568px) {
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
		float: left;
	}
	.col-sm-12 { width: 100%; }
	.col-sm-11 { width: 91.66666667%; }
	.col-sm-10 { width: 83.33333333%; }
	.col-sm-9 { width: 75%; }
	.col-sm-8 { width: 66.66666667%; }
	.col-sm-7 { width: 58.33333333%; }
	.col-sm-6 { width: 50%; }
	.col-sm-5 { width: 41.66666667%; }
	.col-sm-4 { width: 33.33333333%; }
	.col-sm-3 { width: 25%; }
	.col-sm-2 { width: 16.66666667%; }
	.col-sm-1 { width: 8.33333333%; }
}

@media print {
	a[href]:after {
		content: none;
	}
	html, body {
		margin: 0;
		padding: 0;
	}
	.page-break {
		page-break-before: always;
	}
	.page-break-avoid {
		page-break-inside: avoid;
	}
	@page {
		margin: 2cm 1.5cm;
	}
	h4 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}
	.panel {
		page-break-inside: avoid;
	}
	.panel-scrollable {
		overflow: visible;
	}
	.property-group-header {
		margin-top: 5px;
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
}

input[type="date"]:disabled::-webkit-inner-spin-button,
input[type="date"]:disabled::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.property-columns select {
	-webkit-appearance: none;
	-moz-appearance: none;
	text-indent: 1px;
	text-overflow: '';
}








.search-hit {
    font-weight: bold;
    color: black;
}

.search-hit-header {
    margin-left: 1px;
}

.search-li {
    font-size: 12px;
    margin-bottom: 2px;
    margin-left: 5px;
}

.center-on-page {
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.glyphicon-refresh-animate {
    animation: spin .7s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
}
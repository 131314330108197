
.property-group-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 440px);
    justify-content: space-evenly;
    align-content: flex-start;
    list-style-type: none;
    padding: 0;
}

.property-group {
    position: relative;
    margin: 20px;
    width: 400px;
    height: 250px;
    border: 1px solid lightgray;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    background-color: #f9f9f9;
}

.property-group-label {
    position: relative;
    top: 60px;
    text-align: center;
    font-weight: lighter;
}

.property-group-label h1 {
    font-size: 35px;
    font-weight: lighter;
}

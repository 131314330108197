.report-container .tab-content {
    margin-top: 10px;
}

.report-container table th {
    vertical-align: top !important;
}

.amortisation {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.amortisation .progress {
    height: 12px;
    margin: 4px 10px;
    min-width: 120px;
}

.amortisation .progress-bar {
    font-size: 8px;
}

.amortisation .percentage {
    text-align: right;
    width: 40px
}

.amortisation .remaining-months {
    text-align: right;
    width: 90px
}
.report-container table tr.expired-rental-agreement td {
    border-top: none;
    /*color: #d08b41;*/
}

.border-left {
    border-left: 1px solid lightgray;
}

.border-right {
    border-right: 1px solid lightgray;
}
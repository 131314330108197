.property-columns .row {
    padding: 2px 0 2px 0;
}

.property-columns .property-group-header {
    border-bottom: 1px solid #dddddd;
    margin-top: 15px;
    margin-bottom: 5px;
    width: 100%;
}

/* ------------------------------------------------------------- *
 * Property Activity
 * ------------------------------------------------------------- */

.activity {
    margin: 0 15px;
    padding: 10px 0;
    font-size: 12px;
}

.activity-print {
    margin: 0;
    padding: 10px 0;
    font-size: 11px;
}

.activity-n {
    border-top: 1px solid #dddddd !important;
}

.activity-heading {
    padding-top: 5px;
    font-size: 11px;
}

.activity-body {
    padding: 5px 0;
}


/* ------------------------------------------------------------- *
 * Likes
 * ------------------------------------------------------------- */

.like {
    padding: 0 5px 0 5px;
    margin-left: 5px;
    width: 100%;
}

.like-thumb {

}

.like-thumb:hover {
    cursor: pointer;
}

.like-counter {
    padding: 0 5px 0 5px;
}

/* ------------------------------------------------------------- *
 * Property List
 * ------------------------------------------------------------- */

.property-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 440px);
    justify-content: space-evenly;
    align-content: flex-start;
    list-style-type: none;
    padding: 0;
}

.property-tile-panel {
    position: relative;
    border: none;
    margin: 20px;
}

.property-tile-image {
    width: 400px;
    height: 250px;
}

.property-tile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
    cursor: pointer;
}

.property-tile-image-placeholder {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    border-radius: 3px;
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}


.property-tile-image-placeholder i {
    font-size: 200px;
    color: #e0e0e0;
    margin-bottom: 15px;
}

.property-tile-header {
    text-align: left;
    width: 100%;
    margin-bottom: 4px;
    color: rgb(78, 93, 108)
}

.property-tile-footer {
    text-align: left;
    width: 100%;
    margin-top: 2px;
    color: rgb(78, 93, 108)
}

.property-tile-id {
    border-radius: 3px;
    border: 1px solid rgb(78, 93, 108);
    background-color: #fff;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    width: 28px;
    top: 26px;
    left: 4px;
}

.cover-footer-address {
    padding-top: 0;
    display: inline-block;
    font-weight: normal;
    vertical-align: middle;
    /*margin-left: 5px;*/
}

.property-tile-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fcfcfc;
    border: 1px solid rgb(78, 93, 108);
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.6);
    cursor: pointer;
    position: absolute;
    top: 125px;
    text-align: center;
}

.property-tile-arrow i {
    margin: 6px 0 0 0;
}

.property-tile-arrow-left {
    left: -15px;
}

.property-tile-arrow-right {
    right: -15px;
}

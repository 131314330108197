.activity-table > thead > tr > th, .activity-table > tbody > tr > td {
    border: none !important;
}

.activity-table .ruler {
    padding: 0;
    vertical-align: middle;
}

.activity-table .ruler div {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #dddddd;
}
.transaction-table th, .transaction-table td, .transaction-table tbody {
    border: none !important;
}

.transaction-table .transaction-date-header:hover {
    background-color: transparent;
}

.transaction-table .transaction-date-header .ruler {
    padding: 0;
    vertical-align: middle !important;
}

.transaction-table .transaction-date-header .ruler div {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #dddddd;
}

@media print {
    .transaction-row {
        page-break-inside: avoid;
    }
    .transaction-table .transaction-date-header > th {
        background-color: #d9edf7;
    }
}

.transaction-criteria {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.bank-transaction-selector {
    padding: 0 2px 10px 2px;
}

.bank-transaction-selector > div {
    padding: 5px;
}

.ledger-entry-selector {
    padding: 0 2px 10px 2px;
}

.ledger-entry-selector > div {
    padding: 5px;
}

.template-selector > div {
    padding: 5px 10px 5px 0;
}

.new-transaction-criteria > div {
    padding: 5px 10px 5px 0;
}

.mapping-proposal {
    color: red;
}

.create-transaction-modal {
    width: 75%;
}

.create-transaction-modal label {
    font-size: smaller;
    padding-right: 5px;
}

.create-transaction-modal form textarea {
    padding-right: 5px;
    font-size: smaller;
}

.create-transaction-table {
    font-size: smaller;
}

.create-transaction-table th {
    width: 1%;
    white-space: nowrap;
}

.create-transaction-table tbody > tr > td {
    vertical-align: middle;
}

.create-transaction-table select {
    background-color: transparent;
}

.create-transaction-table .flow-selector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.create-transaction-table input {
    text-align: right;
    line-height: 12px !important;
    width: 100%;
}

.create-transaction-table .bank-transaction-id {
    padding: 5px 10px;
}

.create-transaction-table .th-amount {
    background-color: #f2f2f2;
}

.create-transaction-table .td-amount {
    background-color: #f2f2f2;
}

.create-transaction-table .left-side td {
    border-bottom: none;
}

.create-transaction-table .right-side td {
    border-top: none;
}

.create-transaction-table button {
    padding: 0 5px;
}

.create-transaction-table .input-sm {
    padding: 4px 10px;
    height: 28px;
}

.flow-selector > div {
    padding: 0 5px 0 0;
}

.statement-upload-selector {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.statement-upload-selector > div {
    margin-right: 5px;
    font-size: smaller;
}

.transaction-container {

}

.leg-selector {
    width: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leg-selector input {
    margin: 0 0 0 4px;
;

}